import React from "react";
import "./Card.css";


export default function Card({ card, handleChoice, chosen }) {

  function handleClick() {
    handleChoice(card);
  }

  return (
    <div className={chosen && card.matched ? " hide " : chosen && !card.matched ? "chosen" : ""}>
      <div className="Card" onClick={handleClick} tabIndex="0">
        <img className="gem-image" src={card.img} alt={card.cardName} />
        <p className="overlay">{card.cardName}</p>
      </div>
    </div>
  );

}