import React from "react";
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './Home';
import Navbar from './Navbar';
import { Routes, Route } from 'react-router-dom';
import RoomNumbers from './RoomNumbers';
import Card from './Card';

function App() {

  const [showSpinner, setShowSpinner] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 3500)
  }, [])

  return !showSpinner ? (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roomnumbers" element={<RoomNumbers />} />
        <Route path="/card" element={<Card />} />

      </Routes>
    </div>
  ) : (<div className="spinner"> <div className="spin"></div></div>);
}

export default App;
