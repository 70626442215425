import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./Card";
import "./RoomNumbers.css"

const roomNames = [

  { id: 0, cardName: "Malachite", status: "", img: "./images/malachite.png", matched: false },
  { id: 1, cardName: "Chalcedony", status: "", img: "./images/chalcedony.png", matched: false },
  { id: 2, cardName: "Rhodochrosite", status: "", img: "./images/rhodochrosite.jpg", matched: false },
  { id: 3, cardName: "Jasper", status: "", img: "./images/jasper.png", matched: false },
  { id: 4, cardName: "Onyx", status: "", img: "./images/onyx.png", matched: false },
  { id: 5, cardName: "Lapis Lazuli", status: "", img: "./images/lapislazuli.png", matched: false },
  { id: 6, cardName: "Pearl", status: "", img: "./images/pearl.jpg", matched: false },
  { id: 7, cardName: "Amethyst", status: "", img: "./images/amethyst.jpg", matched: false },
  { id: 8, cardName: "Hematite", status: "", img: "./images/hematite.jpg", matched: false },
  { id: 9, cardName: "Aquamarine", status: "", img: "./images/aquamarine.png", matched: false },
  { id: 10, cardName: "Peridot", status: "", img: "./images/peridot.jpg", matched: false },
  { id: 11, cardName: "Citrine", status: "", img: "./images/citrine.jpg", matched: false },
  { id: 12, cardName: "Garnet", status: "", img: "./images/garnet.jpg", matched: false },

];

const roomNumbers = [

  { id: 0, cardName: "101", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 1, cardName: "102", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 2, cardName: "103", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 3, cardName: "104", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 4, cardName: "105", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 5, cardName: "106", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 6, cardName: "107", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 7, cardName: "201", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 8, cardName: "202", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 9, cardName: "203", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 10, cardName: "204", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 11, cardName: "205", status: "", img: "./images/numberbackground.png", matched: false },
  { id: 12, cardName: "206", status: "", img: "./images/numberbackground.png", matched: false },

];
export default function RoomNumbers() {

  let history = useNavigate();
  const shuffleCards = () => { return ([...roomNames, ...roomNumbers].sort(() => Math.random() - 0.5)) }

  const [cards, setCards] = useState(shuffleCards);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [correctPair, setCorrectPair] = useState(null);


  var allMatched = 0;

  function handleBack(e) {

    history(-1);
  }


  function handleChoice(card) {
    if (choiceOne && card.cardName !== choiceOne.cardName) {
      setChoiceTwo(card)
    } else {
      setChoiceOne(card)
    }
  }

  useEffect(() => {

    if (choiceOne && choiceTwo) {

      if (choiceOne.id === choiceTwo.id) {

        setCards(prevCards => {
          return prevCards.map(card => {
            if (card.id === choiceOne.id) {
              allMatched++;
              setCorrectPair(true);
              setTimeout(() => setCorrectPair(null), 2500);
              return { ...card, matched: true };

            } else {
              return card;
            }
          })
        });
        resetChoice();

      } else {
        setCorrectPair(false);
        setTimeout(() => setCorrectPair(null), 2500);

        resetChoice();
      }
    }
  }, [choiceOne, choiceTwo, cards]);

  //reset choices
  function resetChoice() {
    setChoiceOne(null);
    setChoiceTwo(null);
  }

  return (
    <div className="RoomNumbers">
      <div className="feedback">
        {correctPair === true ? <p className="correct">Correct!👏</p> : correctPair === false ? <h1 className="incorrect">Try again 😉</h1> : " "}

      </div>

      <div className="cards">
        <h6 className="game-description">Match the room name & gem to the corresponding room number.
          Every two items you click will be compared. If they are the correct match, they disappear. If
          not, they stay until all items are matched correctly :).

        </h6>

        {
          cards.map((card) => {
            if (card.matched) {
              allMatched++;
              if (allMatched > 25) return (
                <span className="show-result game-description">
                  <h2>Great Job!</h2>
                  <h4>Press the back button to head back or replay</h4>
                </span>)
            }
            return <Card
              card={card}
              handleChoice={handleChoice}
              chosen={card === choiceOne || card === choiceTwo || card.matched} />
          })
        }

      </div >

      <button className="back-btn" onClick={handleBack}>Back</button>
    </div >
  );
}