import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";


export default function Home() {
  return (
    <div className="Home">
      <h1 className="App-header">The Gem Room Games</h1>
      <div className="outline">
        <Link to="/RoomNumbers" className="game-btn-dark">Match Rooms & room numbers</Link>
      </div>
      <div className="outline">
        <Link to="/" className="game-btn">Guess The Room: Coming Soon</Link>
      </div>
    </div >
  );
}