import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"

export default function Navbar() {
  return (
    <div className="Navbar" >
      <Link to="/">
        <div className="home-image " />
      </Link>
    </div >
  );
}